import React, { useState, useContext, useEffect, useRef } from 'react';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import Grid from '@mui/material/Unstable_Grid2';

import PageCards from '../components/ui/PageCards';
import FacilityInfo from '../components/facilityInfo/FacilityInfo';
import FacilityInfoForm from '../components/facilityInfo/FacilityInfoForm';
import DeleteFacilityInfo from '../components/facilityInfo/DeleteFacilityInfo';
import AuthContext from '../components/auth/authContext';

import classes from './Pages.module.css';
import DeleteCardModal from '../components/facilityInfo/DeleteCardModal';

function Admin_Edit_Cards() {
	const authCtx = useContext(AuthContext);

	const [formSuccess, setFormSuccess] = useState(false);
	const [entryDeleted, setEntryDeleted] = useState(false);
	const [entryNotDeleted, setEntryNotDeleted] = useState(false);

	const [addedItem, setAddedItem] = useState(false); // flips back and forth to trigger new state for useEffect in FacilityInfo
	const [deletedItem, setDeletedItem] = useState(false); // flips back and forth to trigger new state for useEffect in FacilityInfo
	const [editEntry, setEditEntry] = useState(null);
	const [editEntrySelected, setEditEntrySelected] = useState(false);
	const [objectToDelete, setObjectToDelete] = useState(null);

	const [displayDeleteCardModal, setDisplayDeleteCardModal] = useState(false);
	const [cardModalTitle, setCardModalTitle] = useState(null);

	// Set dropdown facility
	const [facility, setFacility] = useState('ALL');
	const handleFacilityChange = (event) => {
		setFacility(event.target.value);
	};

	const formSubmissionSuccess = () => {
		setFormSuccess(true);
		setEditEntrySelected(false);
		setEditEntry(null);
		setAddedItem(!addedItem);
		setTimeout(() => {
			setFormSuccess(false);
		}, 3000);
	};

	// If delete button on FacilityInfoCard is selected, delete the card and delete any files
	async function selectDeleteEntry(obj) {
		// Delete the facility info card from the Firebase database
		let response = await DeleteFacilityInfo(obj);

		// If any files are associated with the card, delete them too
		if (obj.Files) {
			deleteFiles(obj);
		}

		// Reset DeleteCardModal state
		cancelCardModal();

		// Reset EditEntry state
		setEditEntrySelected(false);
		setEditEntry(null);

		if (!response) {
			setEntryNotDeleted(true);
			setTimeout(() => {
				setEntryNotDeleted(false);
			}, 3000);
		} else {
			setDeletedItem(() => !deletedItem);
			setEntryDeleted(true);
			setTimeout(() => {
				setEntryDeleted(false);
			}, 3000);
		}
	}

	// Deletes any files associated with a card when the card is deleted
	const deleteFiles = (obj) => {
		const storage = getStorage();

		obj.Files.forEach((file) => {
			// Create a reference to the file to delete
			const reference = ref(storage, file.reference);

			// Delete the file
			deleteObject(reference).catch((err) => {
				console.error(err);
			});
		});
	};

	// If edit button on FacilityInfoCard is selected, pass contents to the form
	const selectEditEntry = (obj) => {
		setEditEntrySelected(false);
		setEditEntry(obj);
	};

	useEffect(() => {
		setEditEntrySelected(true);
	}, [editEntry]);

	// Resets the form when the reset button is selected
	const resetForm = () => {
		setEditEntrySelected(false);
		setEditEntry(null);
		setFormSuccess(false);
	};

	// Cancels the card modal displayed
	const cancelCardModal = () => {
		setCardModalTitle(null);
		setObjectToDelete(null);
		setDisplayDeleteCardModal(false);
	};

	// Displays card modal when Delete button is selected on FacilityInfoCard
	const displayCardModal = (obj) => {
		setDisplayDeleteCardModal(true);
		setCardModalTitle(obj.title);
		setObjectToDelete(obj);
	};

	// Use a ref to store the latest content from the form
	const formContentRef = useRef(null);

	// Function to handle content changes from the FacilityInfoForm
	const handleFormContentChange = (content) => {
		formContentRef.current = content;
	};

	return (
		<Grid container spacing={2}>
			{displayDeleteCardModal && (
				<DeleteCardModal
					objectToDelete={objectToDelete}
					title={cardModalTitle}
					onClose={cancelCardModal}
					onDelete={selectDeleteEntry}
				/>
			)}
			<Grid xs={12} ml={1} mr={1}>
				<PageCards>
					<h1>ADMIN</h1>
					<h3>Select a card on the left to edit its contents.</h3>
				</PageCards>
			</Grid>

			<Grid xs={12} md={4} ml='5px'>
				<PageCards>
					<label className={classes.sort}>
						Sort by Facility:{' '}
						<select value={facility} onChange={handleFacilityChange}>
							<option value='ALL'>All</option>
							<option value='SBLH'>SBLH</option>
							<option value='SI'>SI</option>
							<option value='SLH'>SLH</option>
							<option value='SNGH'>SNGH</option>
							<option value='SOH'>SOH</option>
							<option value='SPAH'>SPAH</option>
							<option value='SVBGH'>SVBGH</option>
							<option value='SSU_SOH'>SSU SOH</option>
						</select>
					</label>
				</PageCards>
				<FacilityInfo
					facility={facility}
					page='Admin'
					addedItem={addedItem}
					deletedItem={deletedItem}
					selectEditEntry={selectEditEntry}
					selectDeleteEntry={displayCardModal}
				/>
			</Grid>

			<Grid xs={12} md={7.9} mr='5px'>
				{formSuccess && <div className={classes.success}>Entry was submitted successfully.</div>}
				{entryDeleted && <div className={classes.success}>The entry was deleted successfully.</div>}
				{entryNotDeleted && <div className={classes.failure}>The entry was not deleted. Please try again.</div>}

				{/* Displays if an editEntry has been selected */}
				{!formSuccess && editEntrySelected && !entryDeleted && !entryNotDeleted && (
					<PageCards>
						<FacilityInfoForm
							formSubmissionSuccess={formSubmissionSuccess}
							editEntry={editEntry}
							resetForm={resetForm}
							onContentChange={handleFormContentChange} // Pass the handler to the form
						/>
					</PageCards>
				)}

				{/* Displays on new load, no editEntry selected */}
				{!formSuccess && !editEntrySelected && !entryDeleted && !entryNotDeleted && (
					<PageCards>
						<FacilityInfoForm
							formSubmissionSuccess={formSubmissionSuccess}
							editEntry={editEntry}
							resetForm={resetForm}
							deleteFile={displayCardModal}
							onContentChange={handleFormContentChange} // Pass the handler to the form
						/>
					</PageCards>
				)}
			</Grid>
		</Grid>
	);
}

export default Admin_Edit_Cards;
